.background-white{
    background-color: white;
}

input{
    border: 1px solid #15504e !important;
    padding: 5px;
    margin: auto;
    width: 50vw !important;
 }

 .textfield{
  width: 37vw !important;
 }

 .btn{
    background-color:  #15504e !important;
    color: #fff;
    font-size: 4px;
    padding: 5px 25px;
    border: 1px solid transparent;
    border-radius: 25px;
    text-transform: uppercase;
    cursor: pointer;
    height: 50%;
    margin: 10px 20px;
 }
 h1{
    color: #15504e;
    margin: 15px !important;
    font-size: 30px !important;
    font-weight: bold !important;
 }

 .active{
   background-color:#15504e !important ;
   color: white !important;
 
   
 }
 
 .active > svg{
   color: white !important;
   stroke: white !important;
   fill: white !important;
 }
 
 .active > span > a{
   color: white !important;
 }
 
 .alert{
  height: 5vh !important;
 }
 
 .container{
   display: flex;
   flex-direction: row;
   grid-template-columns: 1fr 3fr;
   border-radius: 0;
   width: 100vw;
   height: 90.8vh;
   overflow-x: hidden !important;
 
 }

 .row{
   justify-content: space-between !important;
 }

 .add-videos{
   overflow-y: scroll;
 }
 
 .add-videos-body{
   height: 100vh !important;
 }
 