.w-100{
    width: 100% !important;
    float: left;
}

li{
    width: 100% !important;
    font-size: 20px !important;
}

a:hover{
    background-color:#15504e !important ;
    color: white !important;
}
svg{
    color: #15504e !important;
    stroke: #15504e !important;
    fill: #15504e !important;
    margin-left: -2px;
}

span a{
    margin-left: 6px;
    text-align: left !important;
}
span {
    margin-left: 6px;
    text-align: left !important;
}

li:hover svg:not(:hover){
    color: white !important;
    stroke: white !important;
    fill: white !important;
}

