form{
    display: flex;
    flex-direction: column;
    column-gap: 5%;
    width: 60vw;
    margin: auto;
}

.textfield{
    margin: 2% !important;
    width: 37vw !important;
}

.row{
    display: flex;
    flex-direction: row;
    row-gap: 5% !important;
    justify-content: space-between;
    padding: 0% 10%;
    margin:3%;
}

.content_textarea{
    height: 30vh !important;
    padding: 2%;
}