@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --violet-blue-crayola: hsla(234, 50%, 64%, 1);
  --dark-cornflower-blue_a7: hsla(214, 88%, 27%, 0.07);
  --white: hsla(0, 0%, 100%, 1);
  --white_a3: hsla(0, 0%, 100%, 0.03);
  --white_a8: hsla(0, 0%, 100%, 0.08);
  --white_a12: hsla(0, 0%, 100%, 0.12);
  --white_a70: hsla(0, 0%, 100%, 0.7);
  --cultured: hsla(220, 20%, 97%, 1);
  --lavender-web: hsla(233, 52%, 94%, 1);
  --cadet-blue-crayola: hsla(220, 12%, 70%, 1);
  --cadet-blue-crayola_a20: hsla(222, 23%, 71%, 0.2);
  --charcoal: hsla(218, 22%, 26%, 1);
  --raisin-black: hsla(216, 14%, 14%, 1);
  --light-gray: hsla(0, 0%, 79%, 1);
  --blue-crayola: hsla(219, 72%, 56%, 1);
  --black-coral: hsla(220, 12%, 43%, 1);

  --ff-manrope: 'Manrope', sans-serif;

  --fs-1: calc(2.7rem + 1.38vw);
  --fs-2: calc(2.6rem + .66vw);
  --fs-3: 2.2rem;
  --fs-4: 1.9rem;
  --fs-5: 1.8rem;
  --fs-6: 1.7rem;
  --fs-7: 1.5rem;
  --fs-8: 1.4rem;

  --fw-700: 700;


  --section-padding: 90px;

  --shadow-1: 0 0 20px hsla(216, 14%, 14%, 0.05);
  --shadow-2: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
  --shadow-3: 0 0 1.25rem hsla(216, 14%, 14%, 0.04);


  --radius-circle: 50%;
  --radius-pill: 100px;
  --radius-10: 10px;
  --radius-8: 8px;
  --radius-6: 6px;


  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --transition-3: 0.3s ease-in-out;

}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
span,
input,
button,
ion-icon {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}



body.nav-active {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}


.h1,
.h2,
.h3,
.h4,
.h5 {
  color: var(--charcoal);
  font-weight: var(--fw-700);
  line-height: 1.3;
}

.h1 {
  font-size: var(--fs-1);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
}

.h4 {
  font-size: var(--fs-4);
}

.h5 {
  font-size: var(--fs-6);
}

.btn {
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  border: 2px solid var(--white);
  max-width: 100%;
  padding: -12px -5px;
  border-radius: var(--radius-pill);
  transition: var(--transition-1);
  will-change: transform;
}

.btn:is(:hover, :focus-visible) {
  transform: translateY(-4px);
}

.btn-primary,
.btn-outline:is(:hover, :focus-visible) {
  background-color: var(--white);
  color: var(--charcoal);
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-subtitle {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #4d7386;
  font-weight: 700;
  margin-block-end: 16px;
}

.grid-list {
  padding: 5%;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px !important;
}

.w-100 {
  width: 100%;
}

.active{
  background-color:#15504e !important ;
  color: white !important;

  
}

.active > svg{
  color: white !important;
  stroke: white !important;
  fill: white !important;
}

.active > span > a{
  color: white !important;
}


.container{
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 3fr;
  border-radius: 0;
  width: 100vw;
  height: 90.8vh;
  overflow-x: hidden !important;

}
.news{
  overflow-y: scroll;
}

.news-body{
  height: 100vh !important;
}


.news :is(.section-subtitle, .section-title) {
  text-align: center;
}

.news .section-title {
  margin-block-end: 50px;
}


.news-card {
  background-color: var(--white);
  border-radius: var(--radius-8);
  overflow: hidden;
  box-shadow: var(--shadow-2);
  height: 70vh !important;
  width: 100% !important;
}

.news-card .card-content {
  padding: 30px;
}

.news-card .card-title {
  transition: var(--transition-1);
}

.news-card .card-title:is(:hover, :focus-visible) {
  color: #4d7386;
}

.news-card .card-text {
  margin-block: 16px 20px;
}

.news-card .card-meta-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
}

.news-card .card-meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--cadet-blue-crayola);
  font-size: var(--fs-8);
}


@media (min-width: 350px) {
  .news-card .card-content {
    padding: 40px;
  }

  .grid-list {
    padding: 5%;
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 25px !important;
  }
}

@media (min-width: 550px) {
  .news-card .card-content {
    padding: 40px;
  }

  .grid-list {
    padding: 5%;
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 25px !important;
  }
}

@media (min-width: 768px) {
  .news-card .card-content {
    padding: 40px;
  }

  .grid-list {
    padding: 5%;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 25px !important;
  }
}

@media (min-width: 992px) {
  .news-card .card-content {
    padding: 40px;
  }

  .grid-list {
    padding: 5%;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 25px !important;
  }
}

@media (min-width: 1200px) {
  .news-card .card-content {
    padding: 40px;
  }

  .grid-list {
    padding: 5%;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px !important;
  }
}



.modal {
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 80vh;
  overflow: auto;
  background-color: white;
  justify-content: center;
}


button{
  background-color:  #15504e;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.modal-body{
  margin: 5% 3%;
}