.App {
  text-align: center;
   max-width: 100vw !important;
   overflow-x: hidden !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  height: 100%;
  /* background-color: #4d7386 !important; */
}

.section-title {
  margin-block-end: 35px;
  align-items: center;
  justify-content: center;
  color: #4d7386 !important;
}

.subtitle {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: black !important;
  font-weight: 700;
  margin-block-end: 16px;
}

.section-title {
  margin-block-end: 35px;
}

body{
  height: 100% !important;
}

@media (min-width: 768px) {
  .section-title {
    max-width: 30ch;
    margin-inline: auto;
  }
}